<template>
    <div id="pageTable">
        <v-container 
            class="d-flex align-center justify-center" 
            style="height: 80vh; width: 80vw;"
            v-if="rol != 'root'"
        >
			<v-card class="" style="border-radius:20px" max-width="500">
                <v-card-title class="justify-center card_titulo">
                    <div class="headerModal">
                        <img :src="'/static/icon/peligro.svg'" class="imgModal"/>
                    </div>
                </v-card-title>
                <v-card-text class="titleModal text-center">
                    Esta vista no esta disponible para este rol de usuario.
                </v-card-text>
                <v-card-actions class="d-flex align-center justify-center card_accion">
                    <v-btn
                        class="btnGuardar"
                        @click="redireccionar()"
                    >
                        Ok
                    </v-btn>
                </v-card-actions>
            </v-card>
		</v-container>
        <v-container xl fluid v-else>
            <v-row>
                <v-col lg="12">
                    <data-table
                        ref="tableVistas"
                        :tableName="'Vistas del sistema'"
                        :url="url"
                        :columns="columns"
                        :filters="filters"
                        @setFilters="setFilters"
                        :perPage="[10, 25, 50, 100]"
                    >
                        <template slot="actionButtonsLeft">
                            <div class="botonAgregar">
                                <v-btn
                                    rounded
                                    color="#004BAF"
                                    class="btnAdd"
                                    right
                                    @click="abrirModal('add')"
                                >
                                    <v-icon dark class="mr-2">add</v-icon>Agregar
                                    <div class="mr-4"></div>
                                </v-btn>
                            </div>
                        </template>

                        <template slot="filters">
                            <v-col xs="12" sm="6" md="6" class="py-0">
                                <v-autocomplete
                                    outlined
                                    :items="tipos"
                                    item-text="tipo"
                                    item-value="id"
                                    label="Tipo"
                                    persistent-hint
                                    v-model="advancedSearch.tipo"
                                ></v-autocomplete>
                            </v-col>
                            <v-col xs="12" sm="6" md="6" class="py-0">
                                <v-autocomplete
                                    outlined
                                    :items="padres"
                                    item-text="nombre"
                                    item-value="valor"
                                    label="Padre"
                                    persistent-hint
                                    v-model="advancedSearch.padre"
                                ></v-autocomplete>
                            </v-col>
                            <v-col  xs="12" sm="12" md="12" class="py-0">
                                <v-text-field
                                    outlined
                                    label="Nombre"
                                    v-model="advancedSearch.nombre"
                                    class="estiloLetras"
                                />
                            </v-col>
                        </template>

                        <tbody slot="body" slot-scope="{ data }">
                            <tr :key="item.id" v-for="item in data">
                                <td><div>{{ item.nombre }}</div></td>
                                <td><div>{{ item.padre }}</div></td>
                                <td><div class="tblCenter">{{ item.sistema }}</div></td>
                                <td>
                                    <div class="tblOpciones">
                                    <v-tooltip bottom>
                                        <template v-slot:activator="{ on }">
                                            <v-btn
                                                v-on="on"
                                                slot="activator"
                                                class="botonHover"
                                                icon
                                                fab
                                                dark
                                                @click="abrirModal('update', item)"
                                                small
                                            >
                                                <v-icon class="tamIconoBoton invertirColorBotones">edit</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Editar</span>
                                    </v-tooltip>
                                    <v-tooltip bottom>
                                        <template v-slot:activator="{ on }">
                                            <v-btn
                                                v-on="on"
                                                slot="activator"
                                                class="botonHover"
                                                depressed
                                                icon
                                                fab
                                                dark
                                                small
                                                @click="eliminar(item.id, item.nombre)"
                                            >
                                                <v-icon class="tamIconoBoton invertirColorBotonesDelete">delete</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Eliminar</span>
                                      
                                    </v-tooltip>  </div>
                                </td>
                            </tr>
                        </tbody>
                    </data-table>
                </v-col>
            </v-row>
        </v-container>

        <!--  Modal -->
        <template>
            <v-layout row justify-center>
                <v-dialog v-model="dialog" persistent max-width="600px">
                    <template v-slot:activator="{ on }">
                        <v-btn v-on="on" class="botonModal" id="btnModal"></v-btn>
                    </template>
                    <ValidationObserver ref="form" v-slot="{ invalid }">
                        <v-card>
                            <v-card-title class="justify-center card_titulo">
                                <div class="headerModal">
                                    <img :src="imagenModal" class="imgModal" />
                                    <h2 class="titleModal">{{ titulo_modal }}</h2>
                                </div>
                            </v-card-title>

                            <v-card-text class="card_texto">
                                <div id="loadingModal" v-show="!finaliza_carga">
                                    <div id="loading">
                                    <v-progress-circular
                                        indeterminate
                                        :size="100"
                                        :width="7"
                                        color="blue"
                                    ></v-progress-circular>
                                    </div>
                                </div>

                                <v-container grid-list-md id="contenedor">
                                    <v-divider class="divider"></v-divider>
                                    <v-row>
                                        <v-col xs="12" sm="12" md="6"  class="py-0">
                                            <ValidationProvider v-slot="{ errors }" name="Nombre" rules="required">
                                                <v-text-field
                                                    outlined
                                                    label="Nombre"
                                                    v-model="vista.nombre"
                                                    :error-messages="errors"
                                                />
                                            </ValidationProvider>
                                        </v-col>
                                        <v-col xs="12" sm="12" md="6" class="py-0">
                                            <ValidationProvider v-slot="{ errors }" name="Url" rules="required">
                                                <v-text-field
                                                    outlined
                                                    label="URL"
                                                    v-model="vista.url"
                                                    :error-messages="errors"
                                                />
                                            </ValidationProvider>
                                        </v-col>
                                        <v-col xs="12" sm="12" md="6"  class="py-0">
                                            <ValidationProvider v-slot="{ errors }" name="Icono" rules="required">
                                                <v-text-field
                                                    outlined
                                                    label="Icono"
                                                    v-model="vista.icono"
                                                    class="estiloLetras"
                                                    :error-messages="errors"
                                                />
                                            </ValidationProvider>
                                        </v-col>

                                        <v-col xs="12" sm="12" md="6"  class="py-0">
                                            <ValidationProvider v-slot="{ errors }" name="Valor" rules="required">
                                                <v-text-field
                                                    outlined
                                                    label="Valor"
                                                    v-model="vista.valor"
                                                    :error-messages="errors"
                                                    class="estiloLetras"
                                                />
                                            </ValidationProvider>
                                        </v-col>

                                        <v-col xs="12" sm="12" md="6"  class="py-0">
                                            <ValidationProvider v-slot="{ errors }" name="Sistema" rules="required">
                                                <v-autocomplete
                                                    outlined
                                                    v-model="vista.sistema"
                                                    item-text="nombre"
                                                    item-value="id"
                                                    :items="sistemas"
                                                    label="Sistema"
                                                    class="estiloLetras"
                                                    :error-messages="errors"
                                                />
                                            </ValidationProvider>
                                        </v-col>

                                        <v-col xs="12" sm="12" md="6" class="py-0">
                                            <ValidationProvider v-slot="{ errors }" name="Tipo" rules="required">
                                                <v-autocomplete
                                                    outlined
                                                    :items="tipos"
                                                    item-text="tipo"
                                                    item-value="id"
                                                    label="Tipo"
                                                    class="estiloLetras"
                                                    persistent-hint
                                                    v-model="tipo"
                                                    :error-messages="errors"
                                                />
                                            </ValidationProvider>
                                        </v-col>

                                        <template v-if="is_child">
                                            <v-col xs="12" sm="12" md="6" class="py-0">
                                                <ValidationProvider v-slot="{ errors }" name="Padre" rules="required">
                                                    <v-autocomplete
                                                        outlined
                                                        :items="padres"
                                                        item-text="nombre"
                                                        item-value="valor"
                                                        label="Padre"
                                                        class="estiloLetras"
                                                        persistent-hint
                                                        v-model="vista.padre"
                                                        :error-messages="errors"
                                                    />
                                                </ValidationProvider>
                                            </v-col>
                                        </template>
                                    </v-row>
                                </v-container>
                            </v-card-text>
                            <v-card-actions class="d-flex justify-end card_accion">
                                <button
                                    class="btnCerrar"
                                    @click="cerrarModal()" 
                                    :disabled="loading_guardar"
                                >
                                    Cancelar
                                </button>
                                <v-btn
                                    class="btnGuardar"
                                    @click="guardar()" 
                                    :disabled="invalid" 
                                    :loading="loading_guardar"
                                >
                                    Guardar
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </ValidationObserver>
                </v-dialog>
            </v-layout>
        </template>
    </div>
</template>

<script>
import vistasApi from "../api/vistas";
import Swal from "sweetalert2";
import Datatable from "@/components/datatable/Datatable.vue";
import Notify from "@/plugins/notify";
import PerfectScrollbar from "perfect-scrollbar";

export default {
    components: {
        "data-table": Datatable,
    },
    data() {
        return {
        url: "vistas/find",
        columns: [
            { label: "Nombre", name: "nombre", filterable: true },
            { label: "Padre", name: "padre", filterable: true },
            { label: "Sistema", name: "sistema", align:'center', filterable: true },
            { label: "Opciones", name: "opciones", align:'center', filterable: true },
        ],
        filters: {
            activo      : true,
            paginate    : true,
            Nombre      : true,
            Tipo        : null,
            Padre       : null,
        },
        advancedSearch: {
            tipo    : "",
            padre   : "",
            nombre  : "",
            activo  : true,
        },

        loading_guardar : false,
        loading         : true,
        dialog          : false,
        search          : "",
        datosLogin      : "",
        menu            : false,
        vista: {
            id      : 0,
            nombre  : null,
            padre   : null,
            valor   : null,
            url     : null,
            icono   : null,
            tipo    : null,
            sistema : null,
        },
        titulo_modal    : "",
        finaliza_carga  : false,
        accion          : "",
        busqueda        : false,
        is_child        : false,
        tipos: [
            { id: "P", tipo: "Principal" },
            { id: "S", tipo: "Secundario" },
            { id: "M", tipo: "Padre" },
        ],
        tipo        : 1,
        padres      : [],
        iconos      : [],
        sistemas: [
            { id: "gdth", nombre: "GDTH" },
            { id: "nominas", nombre: "Nominas" },
            { id: "todos", nombre: "Todos" },
        ],
        imagenModal : "",
        };
    },
    watch: {
        tipo: function(val) {
            this.vista.tipo = val == "M" ? "M" : "S";
            this.is_child = val == "S" ? true : false;
        },
    },
    methods: {
        listar() {
            this.resetValue();
            this.datosLogin = this.$session.get("usuario");
            this.getPadres();
        },
        setFilters() {
            let padre   = this.advancedSearch.padre;
            let nombre  = this.advancedSearch.nombre;
            let tipo   = this.advancedSearch.tipo;

            this.advancedSearch.padre = null;
            this.advancedSearch.tipo = null;
            this.advancedSearch.nombre = null;

            let filterParams = {
                padre: padre,
                tipo: tipo,
                nombre: nombre,
                activo: true,
            };

            this.filters = filterParams;
        },
        eliminar(id, nombre) {
            let self = this;
            Notify.Alert(
                "¿Estás seguro de eliminar esta vista?",
                "Esta acción <b>puede afectar</b> algunas funcionalidades del sistema.",
                "Eliminar",
                () => {
                this.loading = true;
                vistasApi
                    .deleteVista(id)
                    .then((response) => {
                        self.$refs.tableVistas.getData();
                        this.respuesta_ok(
                            "La vista ha sido eliminada satisfactoriamente"
                        );
                    })
                    .catch((err) => {
                        this.respuesta_error(err);
                    });
                },
                null,
                true,
                true
            );
        },
        cerrarModal() {
            this.dialog = false;
            this.loading_guardar = false;
            this.resetValue();
        },
        resetValue() {
            this.vista = {
                id: 0,
                nombre: null,
                padre: null,
                url: null,
                icono: null,
                tipo: null,
                valor: null,
                sistema: null,
            };
            this.is_child = false;
            this.tipo = 1;
            this.$nextTick(() => {
                this.$refs.form.reset();
            });
        },
        abrirModal(accion, item = null) {
            this.resetValue();
            this.accion = accion;
            this.finaliza_carga = true;

            if (accion == "add") {
                this.titulo_modal = "Registrar vista";
                this.imagenModal = "/static/modal/vistasCreate.svg";
            } else if (accion == "update") {
                this.titulo_modal = "Configurar vista";
                this.imagenModal = "/static/modal/vistasUpdate.svg";
                this.vista.id = item.id;
                this.vista.nombre = item.nombre;
                this.vista.padre = item.padre;
                if (item.tipo == "S" && item.padre == null) {
                    this.tipo = "P";
                } else if (item.tipo == "S" && item.padre != null) {
                    this.tipo = "S";
                } else if (item.tipo == "M") {
                    this.tipo = "M";
                }

                this.vista.url = item.url;
                this.vista.icono = item.icono;
                this.vista.valor = item.valor;
                this.vista.sistema = item.sistema;
            }

            setTimeout(function() {
                document.getElementById("btnModal").click();
            }, 100);
            this.getPadres();
        },
        getPadres() {
            let self = this;
            let params = { activo: true, paginate: false, tipo: "M" };
            vistasApi
                .getVistas(params)
                .then((response) => {
                    self.padres = response.data;
                })
                .catch((err) => {
                    this.respuesta_error(err);
                });
        },
        guardar() {
            this.$refs.form.validate().then(success => {
                this.loading_guardar = true;
                if(!success) {
                    this.loading_guardar = false;
                    return;
                }
                    
                    this.vista.tipo = this.tipo == "M" ? "M" : "S";

                    if (this.accion === "add") {
                        vistasApi
                        .addVista(this.vista)
                        .then((response) => {
                            this.$refs.tableVistas.getData();
                            this.respuesta_ok("Vista creada correctamente.");
                        })
                        .catch((err) => {
                            this.respuesta_error(err);
                        });
                    } else if (this.accion === "update") {
                        vistasApi
                        .updateVista(this.vista)
                        .then((response) => {
                            this.$refs.tableVistas.getData();
                            this.listar();
                            this.respuesta_ok("Actualizado correctamente.");
                        })
                        .catch((err) => {
                            this.respuesta_error(err);
                        });
                    }
            });
        },
        respuesta_error(err) {
            this.loading_guardar = false;
            let error = Object.values(err.response.data.error);
            let leyenda = ``;
            for (var i = 0; i < error.length; i++) {
                leyenda += `* ` + error[i] + `\n`;
            }

            Notify.ErrorToast(leyenda);
        },
        respuesta_ok(msj = "") {
            this.listar();
            this.cerrarModal();
            Notify.Success("Operación exitosa", msj);
        },
        init() {
            const elementScroll = document.querySelector("#scroll");
            if (elementScroll) {
                const ps = new PerfectScrollbar(elementScroll, {
                    wheelSpeed: 2,
                    wheelPropagation: true,
                    minScrollbarLength: 20,
                    suppressScrollY: true,
                });
            }
            $(".ps__thumb-x").css("position", "fixed");
        },
        redireccionar(){
            this.$router.push('/dashboard');
        }
    }, //Fin Methods
    created() {
        this.loading = true;
        this.datosLogin = this.$session.get("usuario");
        this.rol = this.datosLogin.rol.name;
    },
    mounted() {
        this.getPadres();
        this.init();
    },
};
</script>
