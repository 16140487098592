import Vue from "vue";

export default {
  getVistas(parametros) {
    return Vue.axios
      .get("vistas/find", { params: parametros })
      .then((response) => Promise.resolve(response.data))
      .catch((error) => Promise.reject(error));
  },
  getVista(id) {
    return Vue.axios
      .get("vistas/" + id)
      .then((response) => Promise.resolve(response.data))
      .catch((error) => Promise.reject(error));
  },
  updateVista(parametros) {
    return Vue.axios
      .put("vistas", parametros)
      .then((response) => Promise.resolve(response.data))
      .catch((error) => Promise.reject(error));
  },
  deleteVista(id) {
    return Vue.axios
      .delete("vistas/" + id)
      .then((response) => Promise.resolve(response.data))
      .catch((error) => Promise.reject(error));
  },
  addVista(parametros) {
    return Vue.axios
      .post("vistas", parametros)
      .then((response) => Promise.resolve(response.data))
      .catch((error) => Promise.reject(error));
  },
};
